<template functional>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.75 6.49998C12.75 3.04998 9.95 0.249981 6.5 0.249981C3.05 0.249981 0.25 3.04998 0.25 6.49998C0.25 9.94998 3.05 12.75 6.5 12.75C9.95 12.75 12.75 9.94998 12.75 6.49998ZM8.375 3.06248L10.5625 5.24998L8.375 7.43748V5.87498H5.875V4.62498H8.375V3.06248ZM4.625 9.93748L2.4375 7.74998L4.625 5.56248V7.12498H7.125V8.37498H4.625V9.93748Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconSwap'
}
</script>

<style>
</style>
